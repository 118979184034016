/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Container, Flex } from 'theme-ui';
import { Link as ScrollLink } from 'react-scroll';
import Logo from 'components/logo';
import { DrawerProvider } from 'contexts/drawer/drawer.provider';
import MobileDrawer from './mobileDrawer';
import MENU_DATA from './header.data';
import SplitLogoDark from 'assets/images/split-logo-dark.png';
import { motion } from 'framer-motion';


const AnimatedSection = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 80 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

export default function Header({ className }) {
  return (
    <DrawerProvider>
      <AnimatedSection>
        <header sx={styles.header} className={className}>
          <Container sx={styles.container}>
          <Logo image={SplitLogoDark} />
            <Flex as="nav" sx={styles.nav}>
              {MENU_DATA.map(({ path, label }, i) => (
                <ScrollLink
                  activeClass="active"
                  sx={styles.nav.navLink}
                  to={path}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  key={i}
                >
                  {label}
                </ScrollLink>
              ))}
            </Flex>
            <MobileDrawer />
          </Container>
        </header>
      </AnimatedSection>
    </DrawerProvider>
  );
}

const styles = {
  header: {
    color: 'text_white',
    fontWeight: 'normal',
    py: [4, null, null, '25px'],
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'transparent',
    transition: 'all 0.4s ease',

    '&.sticky': {
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
      backdropFilter: 'blur(10px)',
      color: 'text',
      py: '15px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nav: {
    ml: 'auto',
    display: ['none', null, null, null, 'block'],
    navLink: {
      fontSize: '16px',
      color: '#ffffff',
      fontWeight: '400',
      cursor: 'pointer',
      lineHeight: '1.2',
      mr: '48px',
      transition: '500ms',
      ':last-child': {
        mr: '0',
      },
      '&:hover, &.active': {
        color: '#0080FF',
      },
    },
  },
};
