const MENU_DATA = [
  {
    path: 'banner',
    label: 'Home',
  },
  {
    path: 'mission',
    label: 'Mission',
  },
  {
    path: 'services',
    label: 'Service',
  },
  {
    path: 'team-section',
    label: 'About us',
  },
  {
    path: 'contact',
    label: 'Contact',
  },
];
export default MENU_DATA;
